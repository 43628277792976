<template>
  <div class="tab-wrap">
    <div class="handsontable-wrap no-style">
      <data-loader v-bind:isLoading="isLoading"
                   innerText="Подождите, данные загружаются"
                   v-bind:isSpinner="true" />
      <div v-if="!isLoading">
        <hot-table ref="hotTableComponent"
                   v-bind:settings="hotSettings">
        </hot-table>
        <p v-if="noData" class="no-data">Данных не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import { InvestmentObjectChangesDataMapper } from '@/lib/data_mappers/investment_object_changes/investment_object_changes.js';
import { apolloClient } from '@/lib/apollo';
import DataLoader from '@/components/DataLoader.vue';
import { HotTable } from '@handsontable/vue';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import { InvestmentObjectChangesHotTable } from '@/lib/handsontable/investment_object_changes/table.js';
import investment_object_changes_constants from '@/lib/handsontable/investment_object_changes/table_constants.js'

const COUNT_VISIBLE_OBJECTS = 100;

const investmentObjectChanges = new InvestmentObjectChangesDataMapper(apolloClient);

registerLanguageDictionary(ruRU);

export default {
  data() {
    return {
      listChanges: [],
      isLoading: true,
      first: COUNT_VISIBLE_OBJECTS
    };
  },
  props: {
    propertyId: [Number, String]
  },
  components: {
    DataLoader,
    HotTable
  },
  computed: {
    selectedTab() {
      return this.$route.query.tab;
    },
    tabIsSelected() {
      return this.selectedTab == 'investment_object_changes';
    },
    hotRef() {
      return this.$refs.hotTableComponent ? this.$refs.hotTableComponent.hotInstance : null;
    },
    investmentObjectChangesHotTable() {
      return new InvestmentObjectChangesHotTable({
        width: 'auto',
        height: 'auto',
        language: ruRU.languageCode,
        data: this.listChanges
      });
    },
    hotSettings() {
      return this.investmentObjectChangesHotTable.hotSettings;
    },
    noData() {
      return !this.listChanges && !this.isLoading;
    },
    dataIsVisible() {
      return this.listChanges && this.listChanges.length > 0;
    }
  },
  methods: {
    convertChangesIntoHotTableFormat(list) {
      const res = [];

      list.forEach((el) => {
        const listInner = [];
        const columns = investment_object_changes_constants.HOT_TABLE_COLUMNS;

        columns.forEach(col => {
          for (const [key, val] of Object.entries(el)) {
            if (key == col.value) {
              listInner.push(val);
            }
          }
        });

        res.push(listInner);
      });

      return res;
    },

    async getListChanges() {
      if (!this.tabIsSelected) return;

      this.isLoading = true;

      await investmentObjectChanges.getListChanges({
        id: this.propertyId
      }).then(res => {
        if (res) {
          this.listChanges = this.convertChangesIntoHotTableFormat(res);

          this.isLoading = false;
        }
      });
    }
  },
  async mounted() {
    await this.getListChanges();
  },
  watch: {
    async selectedTab(val) {

      if (val == 'investment_object_changes') {
        await this.getListChanges();
      }
    }
  }
};

</script>

<style lang="scss" scoped>
.tab-wrap {
  min-height: 30rem;
  position: relative;
}
</style>
