<template>
  <div>
    <div class="tabs">
      <ul>
        <li v-for="tab in tabs"
            v-bind:key="tab.href"
            v-bind:class="{ 'is-active': tab.isActive }">
          <a v-bind:href="tab.href"
            v-on:click.prevent="selectTab({ name: tab.name, code: tab.code }, handler)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: []
    }
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    handler() {
      return;
    },

    selectTab(args, handler) {
      let query = {...this.$route.query};

      query.tab = args.code;
      this.$router.replace({ query: query }).catch(()=>{});

      this.tabs.forEach(tab => {
        tab.isActive = (tab.name == args.name);
      });

      this.$emit('selectTab', args.code);

      handler();
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid $gray-200;
  list-style: none;
  margin: 0 0 2.5rem;
  padding: 1.5rem 0 0;
  font-size: 1.4rem;

  li {
    margin-bottom: -1px;

    & > a {
      color: #000;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      display: block;
      padding: 1rem;

      &:hover {
        color: $primary;
        border-bottom-color: $primary;
      }
    }

    &.is-active {
      & > a {
        color: $primary;
        border-bottom-color: $primary;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .tabs ul {
    font-size: 1.4rem;
  }
}
</style>