import * as apolloQueries from '../investment_object_changes/queries.js';

export class InvestmentObjectChangesDataMapper {
  #apolloClient = null;

  constructor(apolloClient) {
    this.#apolloClient = apolloClient;
  }

  async getListChanges(args) {
    const x = await this.#apolloClient.query({
      query: apolloQueries.query_changes,
      variables: {
        id: args.id
      },
      fetchPolicy: 'no-cache'
    })
    .then((data, loading) => {
      if (!loading) {
        return data.data.investmentObject.changes;
      }
    })
    .catch((error) => {
      return error;
    });

    return x;
  }
}
  