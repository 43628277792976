<template>
  <div class="main">
    <data-loader v-bind:isLoading="isLoading"
                 innerText="Подождите, данные загружаются"
                 v-bind:isSpinner="true" />
    <div class="container-fluid" v-if="formIsVisible">
      <confirm-modal></confirm-modal>
      <div id="printArea"
           v-if="!isMobile">
        <print-area v-bind:sourceFields="sourceFields"
                    v-bind:sourceArr="sourceArr"
                    v-bind:checkValues="checkValues"
                    ref="printContainer"></print-area>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="highlighted head">
            <div class="head-col">
              <h1>Заполните информацию по квартире</h1>
              <h4>{{ sourceFields.address }}</h4>
              <h4 v-if="sourceFields.jkInfoDisplayName">{{ sourceFields.jkInfoDisplayName }}</h4>
              <head-info-columns v-bind:sourceFields="sourceFields"></head-info-columns>
              <temporary-link v-if="!hasToken"
                              v-bind:objectId="objectId"></temporary-link>
              <div class="w-100">
                <scope-buttons class="mb-3"
                              v-bind:objectId="objectId"
                              v-bind:workflowScopes="currentObject.workflowScopes"
                              v-bind:legalCheckHasError="legalCheckHasError"
                              v-on:showLegalCheckErrorAlert="showLegalCheckErrorAlert"
                              v-on:submitForm="submit"></scope-buttons>
              </div>
              <div class="btn-group mb-3">
                <a href
                   v-bind:class="btnScrollToCommentsClass"
                   v-on:click.prevent="scrollToComments">Перейти к комментариям</a>
                <a v-on:click.prevent="print"
                   v-show="!isMobile && canPrintReport"
                   class="btn btn-primary"
                   id="printButton">Вывести отчет по объекту на печать</a>
              </div>
            </div>
            <div class="head-col">
              <calc-logs-chart v-if="!hasRoleUser"></calc-logs-chart>
            </div>
          </div>
          <form v-on:submit.prevent="submit">
            <tabs v-on:selectTab="selectTab"
                  ref="tabs">
              <tab name="Данные по объекту"
                   code="object_data"
                   v-bind:selected="true"
                   v-on:selectTab="selectTab">
                <div v-for="(s, i) in nestedHeaders"
                     v-bind:key="s"
                     v-bind:class="getSectionClass(s, i)">
                  <h3 v-if="s !== 'head' && s !== 'footer'">{{ s }}</h3>
                  <div class="form-row">
                    <div v-bind:class="getFormGroupClass(el)"
                         v-for="el in getFields(s)"
                         v-bind:key="el.value">
                      <label>
                        <span v-html="el.formTitle || el.title"></span>
                        <span v-on:click="openModalAbout(el)"
                              class="info-modal-opener"
                              v-if="el.modal">i</span>
                      </label>
                      <form-component v-bind:formComponent="el.formComponent || el.type"
                                      v-model="sourceFields[el.value]"
                                      v-bind:isDisabled="isDisabled(el)"
                                      v-bind:field="el"
                                      v-if="!(el.value in $v.sourceFields)"
                                      v-bind:name="el.value"></form-component>
                      <form-component v-bind:formComponent="el.formComponent || el.type"
                                      v-model="$v.sourceFields[el.value].$model"
                                      v-bind:isDisabled="isDisabled(el)"
                                      v-bind:field="el"
                                      v-bind:class="{'error': $v.sourceFields[el.value].$error}"
                                      v-if="el.value in $v.sourceFields" ></form-component>
                      <div v-if="el.note" class="text-muted small" v-html="el.note"></div>
                      <div v-if="'validations' in el && $v.sourceFields[el.value].$error">
                        <span class="text-danger small form-error"
                              v-for="rule in el.validations.rules"
                              v-bind:key="rule">
                            <span v-if="showErrorMessage(el, rule)">
                              {{ el['validations']['errorMessage'][rule] }}
                            </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Список отзывов</h3>
                  <feedback-answers v-bind:propertyId="id"></feedback-answers>
                </div>
              </tab>
              <tab v-for="el in checks"
                   v-bind:key="el.label"
                   v-bind:name="el.label"
                   v-bind:code="el.field[0].groupName"
                   v-on:selectTab="selectTab">
                <div class="form-row align-items-baseline">
                  <div v-for="check in el.field"
                      v-bind:key="check.name"
                      class="form-group col-md-3">
                    <label>{{ check.field.label }}</label>
                    <form-component v-bind:formComponent="checkFormComponentType(check)"
                                    v-bind:field="check.field"
                                    v-model="sourceFields[check.field.mutationName]"
                                    v-bind:options="check.field.values" ></form-component>
                    <div class="small text-muted pt-1">{{ check.field.description }}</div>
                  </div>
                </div>
              </tab>
              <tab name="Документы"
                   code="documents"
                   v-if="documents.length > 0"
                   v-on:selectTab="selectTab"
                   class="documents">
                <div class="form-row align-items-baseline mb-4"
                     v-for="el in documents"
                     v-bind:key="el.label">
                  <h3 class="col-12">{{ el.label }}</h3>
                  <template v-for="check in getCheckFields(el.field)">
                    <div v-if="check.field.isVisible !== false"
                         v-bind:key="check.name"
                         class="form-group col-md-3">
                      <label>{{ check.field.label }}</label>
                      <form-component v-bind:formComponent="checkFormComponentType(check)"
                                      v-bind:field="check.field"
                                      v-model="sourceFields[check.field.mutationName]"
                                      v-bind:options="check.field.values" ></form-component>
                      <div class="small text-muted pt-1">{{ check.field.description }}</div>
                    </div>
                  </template>
                </div>
              </tab>
              <tab name="Оценка ремонта (ML)"
                   code="repair_rating"
                   v-if="isAdminRole"
                   v-on:selectTab="selectTab"
                   v-bind:objectId="objectId">
                <div class="form-group border-bottom pb-3">
                  <label>Укажите общее состояние ремонта</label>
                  <form-component formComponent="dropdown"
                                  v-model="sourceFields.flatInfoRepairType"
                                  v-bind:field="repairTypeField" ></form-component>
                </div>
                <repair-rating-tab></repair-rating-tab>
              </tab>
              <tab name="Клиент"
                   code="client"
                   v-if="isAdminRole">
                <investment-object-client-tab v-bind:clientFields="clientFields"
                                              v-bind:sourceEditedFields="sourceEditedFields"
                                              v-bind:clientId="sourceFields.clientId"
                                              v-bind:clientTags="sourceFields.clientTags"></investment-object-client-tab>
              </tab>
              <tab name="Изменения по объекту"
                   code="investment_object_changes"
                   v-if="isAdminRole">
                <investment-object-changes-tab v-bind:propertyId="id"></investment-object-changes-tab>
              </tab>
            </tabs>
            <div v-if="currentFormState != formState.default"
                class="text-center pt-2">
              <p v-if="currentFormState == formState.no_changed_fields" class="text-warning">Не было добавлено изменений</p>
              <p v-if="currentFormState == formState.pending">Данные отправляются</p>
              <p v-if="currentFormState == formState.success" class="text-success">Данные успешно отправлены</p>
              <p v-if="currentFormState == formState.error" class="text-danger">Произошла ошибка. Данные отправить не удалось</p>
            </div>
            <div class="form-row justify-content-center pb-4 pt-3"
                 v-if="btnIsVisible">
              <button type="submit"
                      class="btn btn-primary btn-lg" >
                Сохранить изменения
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <modal v-bind:title="modalAbout.title"
           ref="modalFieldAbout">
      <template v-slot:modal-body>
        <div v-html="modalAbout.description"></div>
      </template>
      <template v-slot:modal-footer>
        <button type="button"
                class="btn btn-primary"
                v-on:click="closeModalAbout">Ок</button>
      </template>
    </modal>
  </div>
</template>

<script>
import * as internalAdmin from '@/models/internalAdmin';
import * as internalAdminMutations from '@/mutations/internalAdmin';
import DataLoader from '@/components/DataLoader.vue';
import constants from '@/assets/data/grid_constants';
import FormComponent from '@/components/form/FormComponent.vue';
import BaseDataTable from '@/lib/BaseDataTable.js';
import CalcLogsChart from '@/components/CalcLogsChart';
import Tabs from '@/components/common/Tabs.vue';
import Tab from '@/components/common/Tab.vue';
import RepairRatingTab from '@/components/object-form/RepairRatingTab.vue';
import InvestmentObjectChangesTab from '@/components/object-form/InvestmentObjectChangesTab.vue';
import InvestmentObjectClientTab from '@/components/object-form/InvestmentObjectClientTab.vue';
import FeedbackAnswers from '@/components/FeedbackAnswers.vue';
import ScopeButtons from '@/components/object-form/ScopeButtons';
import { mapGetters } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import ConfirmModal from '@/components/common/ConfirmModal.vue';
import detectMobile from '@/lib/detectMobile';
import getInvestmentObjectFields from '@/lib/getInvestmentObjectFields';
import PrintArea from '@/components/object-form/PrintArea.vue';
import HeadInfoColumns from '@/components/object-form/HeadInfoColumns.vue';
import legalCheckAlert from '@/mixins/object-form/legalCheckAlert.js';
import TemporaryLink from '@/components/object-form/TemporaryLink.vue';
import errorHandler from '@/lib/ErrorHandler';
import auth from '@/lib/Auth.js';
import investmentObjectAssignToUsers from '@/lib/investmentObjectAssignToUsers';

const FORM_STATE_DEFAULT = 'default';
const FORM_STATE_NO_CHANGED_FIELDS = 'no_changed_fields';
const FORM_STATE_PENDING = 'pending';
const FORM_STATE_SUCCESS = 'success';
const FORM_STATE_ERROR = 'error';
const CLIENT_GROUP_NAME = 'Клиент';

export default {
  data() {
    return {
      sourceFields: null,
      checkValues: null,
      sourceEditedFields: [],
      formState: {
        default: FORM_STATE_DEFAULT,
        no_changed_fields: FORM_STATE_NO_CHANGED_FIELDS,
        pending: FORM_STATE_PENDING,
        success: FORM_STATE_SUCCESS,
        error: FORM_STATE_ERROR
      },
      currentFormState: FORM_STATE_DEFAULT,
      isLoading: true,
      selectedTab: null,
      currentObject: null,
      modalAbout: {
        title: '',
        description: ''
      }
    }
  },
  mixins: [legalCheckAlert],
  components: {
    DataLoader,
    FormComponent,
    CalcLogsChart,
    Tabs,
    Tab,
    RepairRatingTab,
    FeedbackAnswers,
    ScopeButtons,
    ConfirmModal,
    PrintArea,
    HeadInfoColumns,
    TemporaryLink,
    InvestmentObjectChangesTab,
    InvestmentObjectClientTab,
    Modal: () => import('@/components/common/Modal.vue')
  },
  props: {
    id: [Number, String]
  },
  validations: {
    sourceFields: {
      ratingByExpert: {
        minValue: minValue(constants.ratingByExpertMinValue)
      },
      flatInfoRoomsCount: {
        required
      },
      flatInfoFloorNumber: {
        required
      },
      buildingInfoFloorsCount: {
        required
      },
      flatInfoArea: {
        required
      },
      flatInfoBalconiesCount: {
        required
      },
      flatInfoLoggiasCount: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdminRole: 'isAdminRole',
      getCurrentRole: 'getCurrentRole',
      changedFields: 'investmentObjectFields/getChangedFields'
    }),
    objectId() {
      return this.$route.params.id;
    },
    sourceArr() {
      return constants.favouriteApartmentsGrid;
    },
    computedSourceFields() {
      return Object.assign({}, this.sourceFields);
    },
    formIsVisible() {
      return this.sourceFields && !this.isLoading;
    },
    polygonId() {
      return this.getPolygonId;
    },
    hasToken() {
      return this.$route.query.token;
    },
    nestedHeaders() {
      const arr = this.sourceArr.filter(el => !el.columnGroupIsHiddenOnForm);
      const headerGroups = Array.from(arr, x => x.columnGroup);
      const newArr = headerGroups.filter((el, i) => headerGroups.indexOf(el) == i).filter(el =>  el != null && el != 'Клиент');
      newArr.unshift('head');
      newArr.push('footer');
      return newArr;
    },
    checks() {
      const res = [];
      const group = ['legal_checks', 'views'];

      if (this.checkValues) {
        group.forEach(groupEl => {
          const field = this.checkValues.filter(el => el.groupName == groupEl);
          res.push({
            field,
            label: field[0].field.group.label,
          })
        })
      }

      return res;
    },
    documents() {
      const res = [];
      const group = ['documents_property_title', 'mandatory_documents'];

      if (this.checkValues) {
        group.forEach(groupEl => {
          const field = this.checkValues.filter(el => el.groupName == groupEl);
          res.push({
            field,
            label: field[0].field.group.label,
          })
        })
      }

      return res;
    },
    btnIsVisible() {
      return this.$route.query.tab != 'repair_rating';
    },
    repairTypeField() {
      return this.sourceArr.filter(el => el.value == 'flatInfoRepairType')[0];
    },
    isMobile() {
      return detectMobile();
    },
    canPrintReport() {
      if (!this.getCurrentRole) return;
      return this.isAdminRole || this.getCurrentRole.includes('lawyer') || this.getCurrentRole.includes('valuer');
    },
    btnScrollToCommentsClass() {
      return [
        'btn btn-primary',
        {
          'btn-rounded': this.isMobile || !this.canPrintReport
        }
      ]
    },
    hasRoleUser() {
      return this.$store.getters.getCurrentRole.includes('user');
    },
    clientSourceFields() {
      return this.sourceArr.filter(el => (el.columnGroup == CLIENT_GROUP_NAME || el.section == CLIENT_GROUP_NAME));
    },
    clientSourceKeys() {
      return Array.from(this.clientSourceFields, el => el.value);
    },
    clientFields() {
      return Object.fromEntries(Object.entries(this.sourceFields).filter(([key, value]) => this.clientSourceKeys.includes(key)));
    }
  },
  apollo: {
    investmentObjectChangeFields: {
      query: internalAdmin.investmentObjectChangeFields,
      update: (data) => data,
      result({ data, loading }) {
        if (!loading) {
          this.sourceEditedFields = data.investmentObjectChangeFields;
        }
      }
    },
  },
  methods: {
    getFields(s) {
      return this.sourceArr.filter(el => (el.columnGroup == s || el.section == s) && !el.isHiddenOnForm);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    flatObj(sourceObj) {
      const newObj = {};

      for (const [key, val] of Object.entries(sourceObj)) {
        if (key == 'assignedUser' && !val) {
          newObj['assignedUserContactInfoTelegram'] = '';
          newObj['assignedUserEmail'] = '';
        }

        if ((key == 'buildingInfo' || key == 'flatInfo' || key == 'jkInfo' || key == 'potentialPrices' || key == 'sellerInfo' || key == 'partner' || key == 'clientObjectFeedback' || key == 'client' || key.includes('AtInfo') || key == 'assignedUser' || key == 'assignedUserAtInfo') && val != null)  {
          const value = key == 'potentialPrices' ? val[0] : val;

          for (const [innerKey, innerVal] of Object.entries(value)) {
            if (innerKey != '__typename') {
              newObj[`${key}${innerKey.charAt(0).toUpperCase() + innerKey.slice(1)}`] = innerVal;
            }

            if (innerKey == 'contactInfo') {
              newObj[`${key}${innerKey.charAt(0).toUpperCase() + innerKey.slice(1)}Telegram`] = innerVal ? innerVal.telegram : '';
            }
          }
        } if (key == 'checkValues') {
          val.forEach(el => {
            newObj[el.field.mutationName] = el.value;
          })
        } else if (key == 'potentialPrices' && val == null) {
          newObj[key] = null;
        } else {
          newObj[key] = val;
        }
      }

      return newObj;
    },
    compareObjects(o1, o2) {
      const keys1 = Object.keys(o1);
      const keys2 = Object.keys(o2);

      if (keys1.length != keys2.length) return false;

      for (let key of keys1) {
        if (o1[key] == '' && o2[key] == null) return false;
        if (o1[key] != o2[key]) {
          this.$store.commit('investmentObjectFields/addChangedFields', [{ field: key, value: o1[key].toString() }]);
        }
      }

      return true;
    },
    isDisabled(el) {
      const condition = !this.sourceEditedFields.includes(el.value) && !this.sourceEditedFields.includes(el.mutationFieldName);
      if (el.readOnly) return true;
      if (el.readOnly == false) return false;
      return this.hasToken ? condition || el.hasToken : condition;
    },
    getFormGroupClass(el) {
      const fullWidthArr = ['textarea', 'textarea_with_hyperlinks', 'pre', 'claim_phones'];
      
      return [
        'form-group',
        {
          'col-md-3': fullWidthArr.every(elem => elem != el.formComponent),
          'col-12': fullWidthArr.some(elem => elem == el.formComponent)
        }
      ]
    },
    scrollToFirstInvalidInput() {
      this.$scrollTo('.error', 1000, this.scrollToOptions());
    },
    submit() {
      this.$v.$touch();
      this.$nextTick(() => this.scrollToFirstInvalidInput());
      if (this.$v.$invalid) {
        alert('Пожалуйста, заполните все необходимые поля во всех вкладках');
      }
      if (!this.$v.$invalid && this.changedFields.length == 0) this.currentFormState = FORM_STATE_NO_CHANGED_FIELDS;
      if (!this.$v.$invalid && this.changedFields.length > 0) {
        this.currentFormState = FORM_STATE_PENDING;
        this.sendRequest();
      }
    },
    sendRequest() {
      const investmentObjectChangedFields = this.changedFields.filter(el => el.field != 'assignedUserContactInfoTelegram' && el.field != 'assignedUserEmail');
      const assignedUserObj = this.changedFields.filter(el => el.field == 'assignedUserContactInfoTelegram' || el.field == 'assignedUserEmail');

      if (assignedUserObj.length != 0) {
        const val = assignedUserObj[0].value ? assignedUserObj[0].value : null;

        investmentObjectAssignToUsers([{
          investmentObjectId: this.id,
          query: val
        }]).then(res => {
          this.currentFormState = res ? FORM_STATE_SUCCESS : FORM_STATE_ERROR;
          if (res) {
            this.$store.commit('investmentObjectFields/setChangedFields', []);
          }
        });
      }

      if (investmentObjectChangedFields.length == 0) {
        return;
      }

      this.$apollo.mutate({
        mutation: internalAdminMutations.CHANGE_FIELDS_MUTATIONS,
        variables: {
          investmentObjectId: this.objectId,
          changeFields: investmentObjectChangedFields,
          recalc: true
        },
      })

      .then((data, loading) => {
        if (!loading) {
          const status = data.data.investmentObjectChangeFields.status;
          this.currentFormState = status == true ? FORM_STATE_SUCCESS : FORM_STATE_ERROR;
        }
      })
      .catch((error) => {
        this.currentFormState = FORM_STATE_ERROR;
      });
    },
    getSectionClass(s, i) {
      return {
        'comments': s == 'Комментарии',
        'highlighted': i % 2
      }
    },
    checkFormComponentType(check) {
      return check.field.values.length > 0 ? 'dropdown' : 'input';
    },
    getCheckFields(fields) {
      return fields;
    },
    selectTab(code) {
      this.selectedTab = code;
    },
    showErrorMessage(el, rule) {
      return !this.$v.sourceFields[el.value][rule];
    },
    print() {
      window.document.title = '';

      this.$htmlToPaper('printArea');
    },
    processResult(data) {
      this.currentObject = data;
      this.checkValues = data.checkValues.length > 0 ? data.checkValues : null;
      const newObj = this.flatObj(data);
      this.sourceFields = Object.assign({}, newObj);
    },
    changeDocumentPropertyTitle() {
      this.isLoading = true;

      getInvestmentObjectFields({
        id: this.objectId,
        changeFields: this.changedFields
      }).then(res => {
        if (res) {
          this.isLoading = false;
          this.processResult(res);

          this.$nextTick(() => {
            this.$scrollTo('.documents', 1000, this.scrollToOptions({ offsetDesctop: -71 }));
          });
        } else {
          this.$router.push({
            name: 'Authorization'
          });
        }
      });
    },
    scrollToOptions(args) {
      const offsetDesctop = args && args.hasOwnProperty('offsetDesctop') ? args.offsetDesctop : -51;
      const offsetMobile = args && args.hasOwnProperty('offsetMobile') ? args.offsetMobile : 0;

      const offset = this.isMobile ? offsetMobile : offsetDesctop;

      return { offset };
    },
    scrollToComments() {
      let query = {...this.$route.query};

      if (this.$route.query.tab != 'object_data') {
        query.tab = 'object_data';
        this.$refs.tabs.selectTab({ name: 'Данные по объекту', code: query.tab }, () => {
          this.$nextTick(() => {
            this.$scrollTo('.comments', 1000, this.scrollToOptions());
          });
        });
        return;
      }

      this.$scrollTo('.comments', 1000, this.scrollToOptions());
    },
    beforeWindowUnload(e) {
      if (this.changedFields.length != 0) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    openModalAbout(el) {
      this.modalAbout.title = el.modal.title;
      this.modalAbout.description = el.modal.description;
      this.$refs.modalFieldAbout.openModal();
    },
    closeModalAbout() {
      this.modalAbout.title = '';
      this.modalAbout.description = '';
      this.$refs.modalFieldAbout.closeModal();
    }
  },
  mounted() {
    let query = {...this.$route.query};

    query.tab = this.$route.query.tab || 'object_data';
    this.$router.replace({ query: query }).catch(()=>{});

    getInvestmentObjectFields({
      id: this.objectId,
    }).then(res => {
      if (res) {
        this.isLoading = false;
        this.processResult(res);
      } else {
        errorHandler.handleError(`There is no data about investment object with ID ${this.objectId}`, {
           id: this.objectId,
           uid: auth.uid
        });
        this.$router.push({
          name: 'Authorization'
        });
      }
    });
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  watch: {
    computedSourceFields: {
      deep: true,
      handler(after, before) {
        if (!before || Object.keys(before).length == 0) return;
        this.compareObjects(after, before);
        this.currentFormState = this.formState.default;

        this.checkIfRequiredFieldsFilled(after);

        if (after.check__documentsPropertyTitle__propertyTitle != before.check__documentsPropertyTitle__propertyTitle) {
          this.changeDocumentPropertyTitle();
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.changedFields.length == 0) {
      next();
    } else {
      const answer = window.confirm('У вас есть несохраненные изменения. Вы действительно хотите покинуть страницу?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-row {
  align-items: flex-end;
}

.container-fluid {
  padding-top: 2em;
  padding-bottom: 2em;
}

.head {
  display: grid;
  grid-template-columns: 1fr 42rem;
}

#printArea {display: none;}

@include media-breakpoint-down(md) {
  .container-fluid {
    padding: 0;
  }

  .card {
    margin-top: -1rem;
    box-shadow: none;
  }

  .head {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
}
</style>
