<script>
import * as constants from '@/assets/data/constants.js';

export default {
  computed: {
    repairTypes() {
      return constants.repairTypeObjForm;
    },
    roomTypes() {
      return constants.roomType;
    },
  },
  methods: {
    getRepairTypeText(name) {
      return this.repairTypes.filter(el => el.value == name)[0].text;
    },
    changeToPercent(v) {
      return v > 0 ? `${(v * 100).toFixed(0)} %` : v * 10;
    },
    getScoreHighlightedClass(arr, name) {
      const scores = Object.values(arr);
      const maxVal = Math.max(...scores);

      return {
        'score-highlighted': arr[name] == maxVal
      };
    },
  }
}
</script>
