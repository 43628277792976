import { apolloClient } from './apollo';
import * as internalAdmin from '@/models/internalAdmin';

async function secureToken(id, expiresIn) {
  const x = await apolloClient.query({
    query: internalAdmin.investmentObjectSecureToken,
    variables: {
      investmentObjectId: id,
      expiresIn,
      accessScopes: ['investmentObjectEdit']
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.investmentObject.secureToken;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default secureToken;
