import Handsontable from 'handsontable';
import { EventBus } from '@/patterns/event-bus.js';
import * as DataTableHelper from '@/lib/dataTableHelpers.js';
import obscureEmail from '@/lib/obscureEmail.js';
import DateTimeEditor from '@/lib/handsontable/editors/datetimepicker.js';
import moment from 'moment';
import * as constants from '@/assets/data/constants';

const tableConstants = {
  HOT_TABLE_COLUMNS: [
    {
      title: 'Панель управления',
      width: 120,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);

        let wrapper = document.createElement('div');
        wrapper.className = 'p-1 pb-1';
        
        let btnCreateFeedback = document.createElement('a');
        btnCreateFeedback.className = 'btn btn-sm btn-primary';
        btnCreateFeedback.innerHTML = 'Ответить на отзыв';

        wrapper.appendChild(btnCreateFeedback);

        const userData = instance.getDataAtRow(row);

        Handsontable.dom.addEvent(btnCreateFeedback, 'mousedown', function() {
          EventBus.$emit('openFeedbackModal', {
            feedback: {
              id: userData[1],
              answerMessage: userData[3],
            },
          });
        });

        td.appendChild(wrapper);
      },
    },
    {
      value: 'id',
      title: 'ID',
      width: 50,
      readOnly: true
    },
    {
      value: 'answeredAt',
      title: 'Время ответа на отзыв',
      type: 'numeric',
      readOnly: true,
      width: 120,
      editor: DateTimeEditor,
      customType: 'dateTimeCustom',
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = DataTableHelper.getLocalTime(value);
      },
    },
    {
      value: 'answerMessage',
      title: 'Ответ на отзыв',
      width: 140,
      readOnly: true
    },
    {
      value: 'answerUser',
      title: 'Email пользователя',
      width: 100,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = value != null ? `<a href="mailto:${value.email}">${obscureEmail(value.email)}</a>` : '';
      },
    },
    {
      value: 'createdAt',
      title: 'Время создания отзыва',
      type: 'numeric',
      readOnly: true,
      width: 120,
      editor: DateTimeEditor,
      customType: 'dateTimeCustom',
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = DataTableHelper.getLocalTime(value);
      },
    },
    {
      value: 'message',
      title: 'Текст отзыва',
      width: 140,
      readOnly: true
    },
    {
      value: 'phone',
      title: 'Телефон',
      width: 110,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = value ? DataTableHelper.formatPhone(value) : '';
      },
    }
  ]
};

export default tableConstants;
