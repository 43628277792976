<template>
  <div class="chart-wrap">
    <data-loader v-bind:isLoading="isLoading"
                 innerText="Подождите, данные загружаются"
                 v-bind:isSpinner="true" />
    <vue-apex-charts v-bind:options="chartOptions"
                     v-bind:series="series"
                     v-if="!isLoading"></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts  from 'vue-apexcharts';
import calculationLogs from '@/lib/calculationLogs';
import { formatNumber } from '@/lib/formatNumber.js';
import DataLoader from '@/components/DataLoader.vue';
import moment from 'moment';

const momentFormat = 'DD.MM.YYYY HH:mm';

export default {
  components: {
    VueApexCharts,
    DataLoader
  },
  data() {
    return {
      isLoading: true,
      seriesData: [],
      xAxisCategories: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    polygonId() {
      return this.$store.getters.getPolygonId;
    },
    series() {
      return [{
        name: 'Рыночная цена',
        data: this.seriesData
      }]
    },
    chartOptions() {
      return {
        colors: ['#26c435'],
        title: {
          text: 'Изменение рыночной цены',
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
            fontFamilt: 'Lato'
          }
        },
        chart: {
          type: 'line',
          height: 350,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        xaxis: {
          categories: this.xAxisCategories
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${formatNumber(val)} ₽`;
            }
          }
        },
      }
    },
  },
  methods: {
    loadChart() {
      calculationLogs(this.id).then(res => {
        if (res) {
          const seriesD = Array.from(res, x => x.priceMarket);
          let categories = [];
          this.isLoading = false;
          this.seriesData = seriesD.reverse();
          
          res.forEach(el => {
            const utcTime = moment.utc(el.calculationDate);
            categories.push(moment(utcTime).local().format(momentFormat));
          });

          this.xAxisCategories = categories.reverse();
        }
      });
    },
  },
  mounted() {
    this.loadChart();
  }
};
</script>

<style lang="scss" scoped>
.chart-wrap {
  position: relative;
  min-height: 20rem;
}
</style>
