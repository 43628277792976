import Handsontable from 'handsontable';
import DateTimeEditor from '@/lib/handsontable/editors/datetimepicker.js';
import obscureEmail from '@/lib/obscureEmail.js';
import * as DataTableHelper from '@/lib/dataTableHelpers.js';
import * as constants from '@/assets/data/constants.js';
import Diff from 'vue-jsdiff';

const gerDiff = (valueWas, value) => {
  const diff = Diff.diffWordsWithSpace(valueWas, value);
  const fragment = document.createDocumentFragment();
  let span = null;

  diff.forEach((part) => {
    // green for additions, red for deletions
    // grey for common parts
    const color = part.added ? 'green' : part.removed ? 'red' : 'grey';
    span = document.createElement('span');
    span.style.color = color;
    span.appendChild(document.createTextNode(part.value));
    fragment.appendChild(span);
  });

  return fragment;
};

const formatValue = (v) => {
  if (v && v.toString().includes('T') ) {
    return DataTableHelper.getLocalTime(v)
  }

  if (Array.isArray(v)) {
    if (v.some(el => typeof el == 'object')) {
      let res = '';
      v.forEach(elem => {
        res += JSON.stringify(elem);
      });

      return res;
    } 
    return v.join(', ');
  }

  switch (typeof v) {
    case 'string':
      return v;
    case 'number':
      return v.toString();
    case 'object':
      return JSON.stringify(v);
  }
}

const showFieldsChanges = (v) => {
  if (!v) return;

  let tBody = document.createElement('tbody');
  v.forEach(el => {
    const valDiff = document.createElement('td');
    const valueWas = formatValue(el.valueWas);
    const value = formatValue(el.value);

    if (!gerDiff(valueWas, value)) return;

    valDiff.appendChild(gerDiff(valueWas, value));
    const TR = document.createElement('tr');
    TR.innerHTML = `<td>${el.name}</td><td class="value-was">${valueWas}</td><td class="value-current">${value}</td>`;
    TR.appendChild(valDiff);


    tBody.appendChild(TR);
  });

  let tableWrapOuter = document.createElement('div');
  let tableWrap = document.createElement('div');
  tableWrap.classList.add('investment-object-changes-inner-table-wrap');
  let table = document.createElement('table');
  table.classList.add('investment-object-changes-inner-table');
  let tHead = document.createElement('thead');
  tHead.innerHTML = `
    <tr>
      <th>Имя</th>
      <th>Было</th>
      <th>Стало</th>
      <th>Сравнение</th>
    </tr>
  `;

  table.appendChild(tHead);
  table.appendChild(tBody);
  tableWrap.appendChild(table);
  tableWrapOuter.appendChild(tableWrap);

  return tableWrapOuter.innerHTML;
}

const tableConstants = {
  HOT_TABLE_COLUMNS: [
    {
      value: 'user',
      title: 'Email пользователя',
      width: 100,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = value != null && value.email ? obscureEmail(value.email) : '';
      },
    },
    {
      value: 'user',
      title: 'Роли пользователя',
      width: 100,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = value != null && value.roles.length != 0 ? value.roles.map(el => constants.userRoles[el]).join(', ') : '';
      },
    },
    {
      value: 'atInfo',
      title: 'Время и дата редактирования',
      type: 'numeric',
      readOnly: true,
      width: 120,
      editor: DateTimeEditor,
      customType: 'dateTimeCustom',
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = value ? DataTableHelper.getLocalTime(value.at) : '';
      },
    },
    {
      value: 'fields',
      title: 'Поля',
      type: 'handsontable',
      width: 500,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = showFieldsChanges(value);
      },
      readOnly: true
    }
  ]
};

export default tableConstants;
