import gql from 'graphql-tag';

export const query_changes = gql`
  query query_changes(
    $id: ID!
  ) {
    investmentObject(id: $id) {
      changes {
        user {
          email
          roles
        }
        atInfo {
          at
        }
        fields {
          name
          value
          valueWas
        }
      }
    }
  }
`;
