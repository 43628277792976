<template>
  <div class="btn-group custom"
       v-if="scopeButtonsInner">
    <button v-bind:class="buttonClass(el)"
            v-for="(el, i) in scopeButtonsInner"
            v-bind:key="el.scope"
            v-bind:title="el.title"
            v-bind:data-disabled="el.disabled"
            v-on:click.prevent="changeScopeInner(el, i)">
    </button>
  </div>
</template>

<script>
import { EventBus } from '@/patterns/event-bus.js';
import { scopeButtons, changeScopeMutation } from '@/lib/changeScope';
import { mapGetters } from 'vuex';

export default {
  props: {
    objectId: [Number, String],
    workflowScopes: Array,
    legalCheckHasError: Boolean
  },
  data() {
    return {
      scopeButtonsInner: scopeButtons
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentRole'
    ]),

    isBrokerRole() {
      return this.getCurrentRole.length == 1 && this.getCurrentRole.includes('user');
    },
  },
  methods: {
    legalChecksScopeIsDisabled(scope) {
      return this.isBrokerRole && scope == 'legalChecks' && this.legalCheckHasError;
    },

    buttonClass(el) {
      return `btn btn-sm btn-primary btn-move-to-${el.scope}`;
    },

    changeScopeInner(el, i) {
      if (!el.disabled) {

        EventBus.$emit('openModal', el.title);
        EventBus.$once('changeConfirmState', state => {

          if (state == true) {
            changeScopeMutation(this.objectId, el.scope, true).then(res => {
              if (res) {
                this.$set(this.scopeButtonsInner, i, { ...this.scopeButtonsInner[i], disabled: true });
              }
            });
          }

          return false;
        });
      } else {
        if (this.isBrokerRole) {
          if (this.legalChecksScopeIsDisabled(el.scope)) {
            this.$emit('showLegalCheckErrorAlert');
            return;
          }
          this.$emit('submitForm');
        }
        
        changeScopeMutation(this.objectId, el.scope).then(res => {
          if (res) {
            this.$set(this.scopeButtonsInner, i, { ...this.scopeButtonsInner[i], disabled: false });
          }
        });
      }
    }
  },
  mounted() {
    this.scopeButtonsInner = [...scopeButtons].filter(el => {
      if (el.hiddenForRoles) {
        return el.hiddenForRoles.filter(el => this.getCurrentRole.includes(el)).length != 0 || this.getCurrentRole.length == 0 ? false : el;
      }
      return el;
    });

    this.scopeButtonsInner.forEach((el, i) => {
      if (this.workflowScopes) {
        this.scopeButtonsInner[i].disabled = !this.workflowScopes.some(elInner => elInner == el.scope);
      }
    });
  },
};
</script>
