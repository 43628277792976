<template>
  <div class="btn-group"
        role="group"
        v-bind:aria-label="title">
    <template v-for="el in options">
      <input type="radio"
             v-bind:value="el.value"
             v-bind:key="`${name}_${el.value}_input`"
             class="btn-check"
             v-bind:name="name"
             v-bind:id="`${name}_${el.value}`"
             autocomplete="off"
             v-bind:checked="el.value == value"
             v-on:change="updateValue(el.value)" >
      <label v-bind:key="`${name}_${el.value}_label`"
             v-bind:class="`btn btn-outline-${btnClass}`"
             v-bind:for="`${name}_${el.value}`" >
        {{ el.text }}
      </label>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: [String, Number],
    options: Array,
    title: String,
    btnClass: String,
    code: String
  },
  methods: {
    updateValue(val) {
      this.$emit("change", { code: this.code, val });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &:checked {
    & + .btn {
      background-color: $secondary;
      color: $white;
    }
  }

  &[disabled],
  &:disabled {
    & + .btn {
      pointer-events: none;
      filter: none;
      opacity: 0.2;
    }
  }
}

.btn-group {
  & > .btn {
    margin-right: 0;
    padding: .4rem .6rem;
    font-size: 1.2rem;    
  }

  & > .btn:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
</style>
