import { HotTable } from '@/lib/handsontable/base_table.js';
import constants from '@/lib/handsontable/feedback_answer/table_constants.js';

export class FeedbackAnswersHotTable extends HotTable {
  #settings = {
    licenseKey: process.env.VUE_APP_HANDSONTABLE_LICENSE_KEY,
    data: this.data,
    colHeaders: true,
    stretchH: 'all',
    width: this.width,
    autoWrapRow: true,
    height: this.height,
    rowHeaders: true,
    language: this.language,
    columns: constants.HOT_TABLE_COLUMNS
  }
  
  constructor(args) {
    super(args);
  }

  get hotSettings() {
    return this.#settings;
  }  
}
  