<template>
  <div class="main">
    <div class="handsontable-wrap no-style">
      <data-loader v-bind:isLoading="isLoading"
                   innerText="Подождите, данные загружаются"
                   v-bind:isSpinner="true" />
      <div v-if="!isLoading">
        <hot-table ref="hotTableComponent"
                   v-bind:settings="hotSettings">
        </hot-table>
        <p v-if="noData" class="no-data">Данных не найдено</p>
      </div>
      <div v-if="dataIsVisible">

        <feedback-modal ref="createUserModal"
                    v-on:feedbackCreate="feedbackAnswerCreate"
                    v-bind:queryRes="queryRes"
                    v-bind:queryStatus="queryStatus"></feedback-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { FeedbackAnswerDataMapper } from '@/lib/data_mappers/feedback_answer/feedback_answer.js';
import { apolloClient } from '@/lib/apollo';
import DataLoader from '@/components/DataLoader.vue';
import { HotTable } from '@handsontable/vue';
import FeedbackModal from '@/components/feedback/FeedbackModal';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import { FeedbackAnswersHotTable } from '@/lib/handsontable/feedback_answer/table.js';
import feedback_answer_constants from '@/lib/handsontable/feedback_answer/table_constants.js'

const COUNT_VISIBLE_OBJECTS = 100;

const feedbackAnswer = new FeedbackAnswerDataMapper(apolloClient);

registerLanguageDictionary(ruRU);

export default {
  data() {
    return {
      listFeedbacks: [],
      queryRes: null,
      isLoading: true,
      language: ruRU.languageCode,
      queryStatus: 'default',
      first: COUNT_VISIBLE_OBJECTS,
    };
  },
  props: {
    propertyId: [Number, String]
  },
  components: {
    DataLoader,
    HotTable,
    FeedbackModal
  },
  computed: {
    hotRef() {
      return this.$refs.hotTableComponent ? this.$refs.hotTableComponent.hotInstance : null;
    },
    feedbackAnswersHotTable() {
      return new FeedbackAnswersHotTable({
        width: 'auto',
        height: 'auto',
        language: ruRU.languageCode,
        data: this.listFeedbacks
      });
    },
    hotSettings() {
      return this.feedbackAnswersHotTable.hotSettings;
    },
    noData() {
      return !this.listFeedbacks && !this.isLoading;
    },
    dataIsVisible() {
      return this.listFeedbacks && this.listFeedbacks.length > 0;
    }
  },
  methods: {
    convertUsersIntoHotTableFormat(list) {
      const res = [];

      list.forEach((el) => {
        const listInner = [];
        const columns = feedback_answer_constants.HOT_TABLE_COLUMNS;

        listInner.push('');
        
        columns.forEach(col => {
          for (const [key, val] of Object.entries(el)) {
            if (key == col.value) {
              listInner.push(val);
            }
          }
        });

        res.push(listInner);
      });

      return res;
    },
    async feedbackAnswerCreate(u) {
      this.queryStatus = 'loading';

      feedbackAnswer.createAnswer(u).then(res => {
        if (res) {
          this.queryRes = res.data.clientObjectFeedbacksCreateAnswer.clientObjectFeedback;
          this.queryStatus = res.data.clientObjectFeedbacksCreateAnswer.status ? 'success' : 'error';
        } else {
          this.queryStatus = 'error';
        }
      })
    },
    restoreQueryStatus() {
      this.queryStatus = 'default';
    },
  },
  async mounted() {
    await feedbackAnswer.getListFeedbacks({
      first: this.first,
      orders: [
        {
          field: 'id',
          direction: 'asc'
        }
      ],
      id: this.propertyId
    }).then(res => {
      if (res) {
        this.listFeedbacks = this.convertUsersIntoHotTableFormat(res);

        this.isLoading = false;
      }
    });
  }
};
</script>
