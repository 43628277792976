<template>
  <div class="tab-wrap"
       v-if="clientFieldsInner != null">
    <div class="form-row">
      <div v-bind:class="getFormGroupClass(el)"
           v-for="el in clientSourceFields"
           v-bind:key="el.value">
        <div v-if="el.children && clientPhones.length != 0"
             class="form-row border-bottom pb-3">
          <h3 class="col-12">Телефон</h3>
          <div class="col-12 form-row"
               v-for="(clientPhoneInvestmentObj, index) in clientPhones"
               v-bind:key="clientPhoneInvestmentObj.__typename">
            <h3 class="col-12"
                v-if="clientPhones.length > 1">#{{ index + 1 }}</h3>
            <div v-for="clientPhoneChild in el.children"
                 v-bind:key="clientPhoneChild.value"
                 v-bind:class="getFormGroupClass(clientPhoneChild)">
              <label v-html="clientPhoneChild.title"></label>
              <form-component v-bind:formComponent="clientPhoneChild.formComponent || clientPhoneChild.type"
                              v-bind:isDisabled="isDisabled(clientPhoneChild)"
                              v-bind:field="clientPhoneChild"
                              v-model="clientPhoneInvestmentObj[clientPhoneChild.value]"
                              v-bind:name="`${clientPhoneChild.value}_${index + 1}`"></form-component>
            </div>
          </div>
          <button class="btn btn-primary"
                  type="button"
                  v-on:click.prevent="updateClient">Сохранить данные о телефоне</button>
        </div>
        <template v-if="!el.children">
          <label v-html="el.title"></label>
          <form-component v-bind:formComponent="el.formComponent || el.type"
                          v-model="clientFieldsInner[el.value]"
                          v-bind:isDisabled="isDisabled(el)"
                          v-bind:field="el"
                          v-bind:name="el.value"
                          v-if="el.formComponent != 'multiselect'"></form-component>
        
          <multi-select v-if="el.formComponent == 'multiselect'"
                        v-bind:value="clientMultiSelectObj"
                        v-bind:options="listTags"
                        v-bind:searchable="true"
                        trackBy="name"
                        v-on:input="setClientObj"
                        v-on:onSearch="onSearch"
                        label="name"
                        ref="clientTags">
            <span slot="noResult">Не найдено ни одного тега</span>
          </multi-select>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/assets/data/grid_constants';
import FormComponent from '@/components/form/FormComponent.vue';
import { ClientDataMapper } from '@/lib/data_mappers/client/client.js';
import { apolloClient } from '@/lib/apollo';
import MultiSelect from '@/components/users/MultiSelect.vue';

const CLIENT_GROUP_NAME = 'Клиент';

export default {
  props: {
    clientFields: Object,
    sourceEditedFields: Array,
    clientId: [Number, String],
    clientTags: Array
  },
  data() {
    return {
      clientFieldsInner: null,
      listTags: [],
      clientMultiSelectObj: null,
      clientPhones: [],
      clientInputTags: null
    };
  },
  components: {
    FormComponent,
    MultiSelect
  },
  computed: {
    sourceArr() {
      return constants.favouriteApartmentsGrid;
    },
    clientSourceFields() {
      return this.sourceArr.filter(el => (el.columnGroup == CLIENT_GROUP_NAME || el.section == CLIENT_GROUP_NAME) && !el.isHiddenOnForm);
    },
    hasToken() {
      return this.$route.query.token;
    },
    computedClient() {
      return Object.assign({}, this.clientFieldsInner);
    },
    clientInputDefaultValues() {
      const phones = [...this.clientFields.clientPhones].map(el => {
        let obj = Object.assign({}, el);
        delete obj.__typename;
        return obj;
      });
      return {
        comment: this.clientFields.clientComment,
        name: this.clientFields.clientName,
        phones,
        tags: Array.from(this.clientFields.clientTags, el => el.id)
      }
    },
    clientInput() {
      return {
        comment: this.clientFieldsInner.clientComment,
        name: this.clientFieldsInner.clientName,
        phones: this.clientPhones,
        tags: this.clientInputTags || Array.from(this.clientFieldsInner.clientTags, el => el.id)
      }
    }
  },
  methods: {
    getFormGroupClass(elem) {
      const formControls = ['textarea', 'textarea_with_hyperlinks', 'pre', 'multiselect'];
      return [
        'form-group',
        {
          'col-12': formControls.some(el => el == elem.formComponent) || elem.hasOwnProperty('children'),
          'col-md-3': formControls.every(el => el != elem.formComponent) && !elem.hasOwnProperty('children')
        }
      ]
    },
    isDisabled(el) {
      const condition = !this.sourceEditedFields.includes(el.value) && !this.sourceEditedFields.includes(el.mutationFieldName);
      if (el.readOnly) return true;
      if (el.readOnly == false) return false;
      return this.hasToken ? condition || el.hasToken : condition;
    },
    compareObjects(o1, o2) {
      const keys1 = Object.keys(o1);
      const keys2 = Object.keys(o2);

      if (keys1.length != keys2.length) return false;

      for (let key of keys1) {
        if (o1[key] == '' && o2[key] == null) return false;
        if (o1[key] != o2[key]) {
          this.$store.commit('investmentObjectFields/addChangedFields', [{ field: key, value: o1[key].toString() }]);
        }
      }

      return true;
    },
    compareClientInput(o1, o2) {
      const keys1 = Object.keys(o1);
      const keys2 = Object.keys(o2);

      if (keys1.length != keys2.length) return false;

      const res = [];

      for (let key of keys1) {
        if (o1[key] == '' && o2[key] == null) return false;
        else if (JSON.stringify(o1[key]) != JSON.stringify(o2[key])) {
          res.push({ field: key, value: JSON.stringify(o1[key]) });
        }
      }

      return res;
    },
    onSearch(query) {
      if(query.length) {
        this.$refs.clientTags[0].changeLoadingState(true)
        if (this.client.clientTags.length > 0) {
          this.$refs.clientTags[0].changeLoadingState(false);
          this.listTags = this.client.clientTags;
          return;
        }
        this.client.getClientTags({ all: true, active: true }).then((res) => {
          this.listTags = res;
          this.$refs.clientTags[0].changeLoadingState(false);
        });
      }
    },
    setClientObj(v) {
      this.clientMultiSelectObj = v;
      const tags = Array.from(v, x => +x.id);
      this.clientInputTags = tags;
      this.client.changeTags({ tags });
    },
    updateClient() {
      const changes = this.compareClientInput(this.clientInputDefaultValues, this.clientInput);
      const investmentObjectId = this.$route.params.id;

      if (changes.length == 0) return;
      if (changes.every(el => el.field != 'phones')) {
        this.client.updateClient(this.clientInput, investmentObjectId);
        return;
      }
      
      if (confirm('Вы уверены, что хотите изменить данные о телефоне клиента?')){
        this.client.updateClient(this.clientInput, investmentObjectId);
        return false;
      }
    }
  },
  mounted() {
    this.clientFieldsInner = Object.assign({}, this.clientFields);
    this.clientPhones = Object.keys(this.clientFields).length != 0 ? [...this.clientFieldsInner.clientPhones].map(el => {
      let obj = Object.assign({}, el);
      delete obj.__typename;
      return obj;
    }) : [];

    this.client = new ClientDataMapper(apolloClient);
    this.client.setClientId(+this.clientId);

    if (this.clientTags && this.clientTags.length > 0) {
      this.clientMultiSelectObj = this.clientTags.map(el => {
        return { name: el.name, id: +el.id }
      });
    }
  },
  watch: {
    computedClient: {
      deep: true,
      handler(after, before) {
        if (!before || Object.keys(before).length == 0) return;
        this.compareObjects(after, before);
      }
    }
  },
};

</script>
