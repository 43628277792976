import gql from 'graphql-tag';
import * as internalAdmin from '@/models/internalAdmin';

export const CHANGE_FIELDS_MUTATIONS = gql`
  mutation change_fields(
    $investmentObjectId: ID!,
    $changeFields: [ChangeFieldInputValue!]!,
    $recalc: Boolean
  ) {
    investmentObjectChangeFields(input: {
      investmentObjectId: $investmentObjectId,
      changeFields: $changeFields,
      recalc: $recalc
    }) {
      investmentObject {
        ${internalAdmin.favouriteObjectsFields({ showPhones: false })}
      }
      status
    }
  }
`;

export const CHANGE_SCOPE_MUTATION = gql`
  mutation change_fields(
    $investmentObjectId: ID!,
    $scope: SelectScopeEnum!,
    $remove: Boolean
  ) {
    investmentObjectChangeScope(
      input: {
        investmentObjectId: $investmentObjectId,
        scope: $scope,
        remove: $remove
      }
    ) {
      investmentObject {
        ${internalAdmin.favouriteObjectsFields({ showPhones: false })}
      }
      status
    }
  }
`;

export const START_CALCULATION_MUTATION = gql`
  mutation start_calculation(
    $investmentObjectId: ID!,
    $startNow: Boolean
  ) {
    investmentObjectStartCalculation(
      input: {
        investmentObjectId: $investmentObjectId,
        startNow: $startNow
      }
    ) {
      status
    }
  }
`;

export const CHANGE_GROUP_MUTATION = gql`
  mutation change_group(
    $investmentObjectId: ID!,
    $groupId: ID!
  ) {
    investmentObjectChangeScope(
      input: {
        investmentObjectId: $investmentObjectId,
        groupId: $groupId
      }
    ) {
      investmentObject {
        workflowScopes
      }
      status
    }
  }
`;

export const CREATE_CALCULATION_CLAIM_MUTATION = gql`
  mutation create_calculation_claim(
    $claim: InternalAdmin__CalcClaimInput,
    $externalSourceUrl: String,
    $groupIds: [ID!]
  ) {
    createCalculationClaim(
      input: {
        claim: $claim,
        externalSourceUrl: $externalSourceUrl,
        groupIds: $groupIds
      }
    ) {
      calcId
    }
  }
`;

export const QUERY_CREATE_MUTATION = gql`
  mutation query_create(
    $filters: [InternalAdmin__InvestmentObject__FilterField!]
    $name: String!
    $type: TypeEnum!
    $default: Boolean,
    $userId: ID
  ) {
    queryCreate(
      input: {
        filters: $filters,
        name: $name,
        type: $type,
        default: $default,
        userId: $userId
      }
    ) {
      query {
        default
        id
        name
        filters {
          condition
          name
          value
          value2
        }
      }
      status
    }
  }
`;

export const QUERY_CREATE_FIXED_FOR_USER_MUTATION = gql`
  mutation query_create_fixed_for_user(
    $filters: [InternalAdmin__InvestmentObject__FilterField!]
    $name: String
    $userId: ID!
    $overrideAll: Boolean,
    $polygonsString: String
  ) {
    queryCreateFixedForUser(
      input: {
        filters: $filters,
        name: $name,
        userId: $userId,
        overrideAll: $overrideAll,
        polygonsString: $polygonsString
      }
    ) {
      query {
        default
        id
        name
        filters {
          condition
          name
          value
          value2
        }
      }
      status
    }
  }
`;

export const QUERY_DELETE_MUTATION = gql`
  mutation query_delete(
    $id: ID!,
    $userId: ID
  ) {
    queryDelete(
      input: {
        id: $id,
        userId: $userId
      }
    ) {
      query {
        default
        id
        name
        filters {
          condition
          name
          value
          value2
        }
      }
      status
    }
  }
`;

export const INVESTMENT_OBJECT_ASSIGN_TO_ME_MUTATION = gql`
  mutation investmentObjectAssignToMe(
    $fetchCount: Int,
  ) {
    investmentObjectAssignToMe(
      input: {
        fetchCount: $fetchCount,
      }
    ) {
      status
      error {
        ... on InternalAdmin__InvestmentObject__AssignToMe__ErrorReachLimitInWork {
          count
          limit
          message
        }
        ... on InternalAdmin__InvestmentObject__AssignToMe__ErrorReachLimitToDay {
          count
          limit
          message
        }
      }
      investmentObjects {
        id
      }
    }
  }
`;

export const INVESTMENT_OBJECT_ASSIGN_TO_USERS = gql`
  mutation investmentObjectAssignToUsers(
    $usersToObjects: [InternalAdmin__InvestmentObject__AssignToMe__UserToObjectInput!]!,
  ) {
    investmentObjectAssignToUsers(
      input: {
        usersToObjects: $usersToObjects,
      }
    ) {
      status
      investmentObjects {
        id
        assignedUser {
          email
        }
        assignedUserAtInfo {
          at
        }
      }
      usersWithError {
        user {
          email 
        }
        error {
          ... on InternalAdmin__InvestmentObject__AssignToMe__ErrorReachLimitInWork {
            count
            limit
          }
          ... on InternalAdmin__InvestmentObject__AssignToMe__ErrorReachLimitToDay {
            count
            limit
          }
        }
      }
    }
  }
`;
