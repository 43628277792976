<template>
  <div>
    <p>
      <a v-on:click.prevent="generateTemporaryLink"
         class="link-style">Сгенерировать временную ссылку (срок действия - 2 месяца)</a>
    </p>
    <div v-if="clipboardPanelIsVisible" class="clipboard-area">
      <input type="text"
              v-bind:value="temporaryEditLink"
              ref="temporaryEditLink"
              class="form-control">
      <button v-on:click="copyToClipboard"
              class="btn btn-primary">{{ clipboardBtnText }}</button>
    </div>
  </div>
</template>

<script>
import constants from '@/assets/data/grid_constants';
import secureToken from '@/lib/secureToken';

export default {
  props: {
    objectId: [Number, String]
  },
  data() {
    return {
      temporaryEditLink: '',
      clipboardPanelIsVisible: false,
      clipboardBtnText: 'Скопировать в буфер обмена',
    };
  },
  methods: {
    generateTemporaryLink() {
      const expiresIn = constants.secureTokenExpiresIn; 

      secureToken(this.objectId, expiresIn)
        .then(res => {
          if (res) {
            this.temporaryEditLink = `${constants.magicUrl}object-form/${this.objectId}?token=${res}`;
            this.clipboardPanelIsVisible = true;
          }
        });
    },

    copyToClipboard() {
      const copyText = this.$refs.temporaryEditLink;
      copyText.select();
      document.execCommand("copy");

      this.clipboardBtnText = 'Ссылка скопирована';
    },
  }
}
</script>

<style lang="scss" scoped>
.clipboard-area {
  display: grid;
  grid-template-columns: 1fr 24rem;
  margin-bottom: 2rem;
  grid-gap: 1.5rem;
}

@include media-breakpoint-down(sm) {
  .clipboard-area {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }
}
</style>
