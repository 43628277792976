import { apolloClient } from './apollo';
import * as internalAdmin from '@/models/internalAdmin';

async function getInvestmentObjectFields(args) {
  const x = await apolloClient.query({
    query: internalAdmin.investmentObjectDetailScope,
    variables: {
      id: args.id,
      fieldNames: args.fieldNames,
      changeFields: args.changeFields
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.investmentObject;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default getInvestmentObjectFields;
