<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: { required: true },
    code: { required: true },
    selected: { default: false}
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    href() {
      return '#' + this.code;
    }
  },
  mounted() {
    this.isActive = this.code == this.$route.query.tab;
    this.$emit('selectTab', this.code);
  }
};
</script>

<style lang="scss" scoped>

</style>
