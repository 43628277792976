import { HotTable } from '@/lib/handsontable/base_table.js';
import constants from '@/lib/handsontable/investment_object_changes/table_constants.js';

export class InvestmentObjectChangesHotTable extends HotTable {
  #settings = {
    licenseKey: process.env.VUE_APP_HANDSONTABLE_LICENSE_KEY,
    data: this.data,
    colHeaders: true,
    stretchH: 'all',
    width: this.width,
    autoWrapRow: true,
    height: this.height,
    rowHeaders: true,
    language: this.language,
    columns: constants.HOT_TABLE_COLUMNS,
    columnSorting: {
      initialConfig: {
        column: 2,
        sortOrder: 'asc'
      },
      compareFunctionFactory: (sortOrder, columnMeta) => {
        return function(value, nextValue) {
          return value.at < nextValue.at;
        }
      }
    }
  }
  
  constructor(args) {
    super(args);
  }

  get hotSettings() {
    return this.#settings;
  }  
}
  