import * as apolloQueries from '../feedback_answer/queries.js';

export class FeedbackAnswerDataMapper {
  #apolloClient = null;

  constructor(apolloClient) {
    this.#apolloClient = apolloClient;
  }

  async createAnswer(args) {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_create_answer,
      variables: {
        answerMessage: args.answerMessage,
        id: args.id
      },
    })
    .then((data, loading) => {
      if (!loading) {
        return data;
      }
    })
    .catch((error) => {
      return error;
    });

    return x;
  }

  async getListFeedbacks(args) {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.query_feedbacks,
      variables: {
        first: args.first,
        orders: args.orders,
        id: args.id
      },
    })
    .then((data, loading) => {
      if (!loading) {
        return data.data.investmentObject.clientObjectFeedbacks.nodes;
      }
    })
    .catch((error) => {
      return error;
    });

    return x;
  }
}
  