<template>
  <div class="tab-wrap">
    <h2 class="mb-4">Оценка производится на основании фотографий из объявления</h2>
    <data-loader v-bind:isLoading="isLoading"
                 innerText="Подождите, производится оценка"
                 v-bind:isSpinner="true" />
    <div class="results"
         v-if="investmentObjectImagesRepairScore">
      <div v-if="calcStatus && calcStatus != 'ok'"
           v-bind:class="`alert alert-${getStatusClass(calcStatus)} mb-4`">
        {{ getStatusText(calcStatus) }}
      </div>
      <p class="text-muted">
        Версия алгоритма: {{ algorithmVersion }}
      </p>
      <h3 v-if="!ifObjIsNotEmpty(investmentObjectImagesRepairScore.scores)">Общая оценка ремонта:</h3>
      <ul class="score-list border-bottom">
        <li v-for="(value, name) in investmentObjectImagesRepairScore.scores"
            v-bind:key="`score_${name}`">
          <span v-bind:class="getScoreHighlightedClass(investmentObjectImagesRepairScore.scores, name)">
            {{ getRepairTypeText(name) }}: {{ changeToPercent(value) }}
          </span>
        </li>
      </ul>
      <div class="images-grid">
        <div class="img-wrap"
            v-for="(el, index) in investmentObjectImagesRepairScore.images"
            v-bind:key="index">
          <repair-rating-img v-bind:el="el"
                             v-bind:index="index"
                             v-bind:calcStatus="calcStatus"
                             v-bind:modelVersion="algorithmVersion"></repair-rating-img>
        </div>
      </div>
    </div>
    <div v-if="hasError"
         class="error-message text-center pt-4">
      Не удалось оценить ремонт. Попробуйте, пожалуйста, позже
    </div>
  </div>
</template>

<script>
import * as internalAdmin from '@/models/internalAdmin';
import DataLoader from '@/components/DataLoader.vue';
import RepairRatingImg from '@/components/RepairRatingImg.vue';
import errorHandler from '@/lib/ErrorHandler';
import RepairRatingBase from '@/components/object-form/RepairRatingBase.vue';

const repairCalcStatus = [
  {
    value: null,
    description: 'Не удалось оценить ремонт. Попробуйте, пожалуйста, позже.',
    alertClass: 'warning'
  },
  {
    value: 'none',
    description: 'Не удалось оценить ремонт. Попробуйте, пожалуйста, позже.',
    alertClass: 'warning'
  },
  {
    value: 'not_images_for_score',
    description: 'Не удалось подгрузить ни одной картинки.',
    alertClass: 'warning'
  },
  {
    value: 'ok',
  },
  {
    value: 'algorithm_error',
    description: 'Не удалось произвести расчет, алгоритм вернул ошибку.',
    alertClass: 'warning'
  }
];

export default {
  extends: RepairRatingBase,
  data() {
    return {
      isLoading: true,
      hasError: false,
      calcStatus: null,
      algorithmVersion: null,
    };
  },
  components: {
    DataLoader,
    RepairRatingImg
  },
  computed: {
    objectId() {
      return this.$route.params.id;
    },
    skipQuery() {
      return this.$route.query.tab != 'repair_rating';
    },
  },
  apollo: {
    investmentObjectImagesRepairScore: {
      query: internalAdmin.investmentObjectImagesRepairScore,
      update: (data) => data.investmentObjectImagesRepairScore,
      result({ data, loading }) {
        const res = data.investmentObjectImagesRepairScore;
        if (!loading) {
          this.isLoading = false;
          
          this.hasError = !data || (!res.algorithmVersion && !res.status);

          if (!data) {
            errorHandler.handleError('did not manage to rate the repair');
          } else {
            this.calcStatus = res.status;
            this.algorithmVersion = res.algorithmVersion || null;
          }
        }
      },
      variables() {
        return {
          id: this.objectId,
        };
      },
      skip() {
        return this.skipQuery;
      },
    },
  },
  methods: {
    getStatusText(val) {
      return this.calcStatus ? repairCalcStatus.filter(el => el.value == val)[0].description : '';
    },
    getStatusClass(val) {
      return this.calcStatus ? repairCalcStatus.filter(el => el.value == val)[0].alertClass : '';
    },
    ifObjIsNotEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
    }
  }
};

</script>

<style lang="scss" scoped>
.tab-wrap {
  min-height: 30rem;
  position: relative;
}

.images-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.score-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.border-bottom {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.score-highlighted {
  padding: 0.2em;
  background-color: #fcf8e3;
}

@include media-breakpoint-down(md) {
  .images-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@include media-breakpoint-down(sm) {
  .images-grid {
    grid-template-columns: 1fr;
  }
}
</style>
