const requiredFields = [
  'flatInfoAreaKitchen',
  'flatInfoAreaLiving',
  'flatInfoRepairType',
  'flatInfoRoomsCount',
  'flatInfoFloorNumber',
  'check__legalChecks__checkOwnersCount',
  'buildingInfoFloorsCount',
  'buildingInfoMaterialType',
  'check__views__nonIsolatedRooms',
  'flatInfoBalconiesCount',
  'flatInfoLoggiasCount',
  'check__views__checkViewRoadNoiseWhenWindowOpen',
  'check__views__checkViewBadViewFromWindow',
  'check__views__checkWoodenFloorsBetweenFloors',
  'check__views__checkViewAllRoomsHaveWindow'
];

const groups = [
  {
    fieldValue: 'legalChecks',
    groupValue: 'legal_checks'
  },
  {
    fieldValue: 'views',
    groupValue: 'views'
  }
];

export default {
  data() {
    return {
      legalCheckHasError: true,
      emptyFields: []
    };
  },
  methods: {
    showLegalCheckErrorAlert() {
      if (!this.legalCheckHasError) return;

      let textMessage = 'Заполните, пожалуйста, следующие поля: \n';
      let tab = '';

      this.emptyFields.forEach(el => {
        let label = '';
        if (el.startsWith('check__')) {
          const groupNameFromFieldName = el.split('__')[1];
          const groupName = groups.filter(el => el.fieldValue == groupNameFromFieldName)[0].groupValue;
          const group = this.checks.filter(gr => gr.field[0].groupName == groupName)[0];
          tab = group.label;

          label = group.field.filter(f => f.field.mutationName == el)[0].field.label;
        } else {
          tab = 'Данные по объекту';

          label = this.sourceArr.filter(elem => elem.value == el)[0].title;
        }

        textMessage += `\n${label} (${tab})`;
      })

      alert(textMessage);
    },
    checkIfRequiredFieldsFilled(fields) {
      this.emptyFields = [];

      requiredFields.forEach(el => {
        if ((el == 'flatInfoBalconiesCount' || el == 'flatInfoLoggiasCount') && fields[el] == 0) return;
        if (!fields[el] || fields[el] == 'none') {
          this.emptyFields.push(el)
        }
      });

      this.legalCheckHasError = this.emptyFields.length != 0;
    }
  }
}
