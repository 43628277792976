import gql from 'graphql-tag';

export const mutation_create_answer = gql`
  mutation mutation_create_answer(
    $answerMessage : String!
    $id: ID!
  ) {
    clientObjectFeedbacksCreateAnswer(
      input: {
        answerMessage: $answerMessage,
        id: $id,
      }
    ) {
      clientObjectFeedback {
        id
        answeredAt
        answerMessage
        answerUid
      }
      status
    }
  }
`;

export const query_feedbacks = gql`
  query query_feedbacks(
    $first: Int,
    $orders: [InternalAdmin__ClientObjectFeedback__Order!],
    $id: ID!
  ) {
    investmentObject(id: $id) {
      clientObjectFeedbacks(
        first: $first,
        orders: $orders,
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        nodes {
          answeredAt
          answerMessage
          answerUser {
            email
          }
          createdAt
          id
          message
          phone
          answerUid
        }
      }
    }
  }
`;
