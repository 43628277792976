<template>
  <div class="row">
    <div class="col-md-auto">
      <p class="mb-1"><strong>Добавлено в базу:</strong> {{ getDate('externalFirstCrawlerAt') }}</p>
      <p class="mb-1"
         v-if="getDate('calculationDate')"><strong>Рассчитано:</strong> {{ getDate('calculationDate') }}</p>
      <p class="mb-1"
         v-if="publicUrl">
        <a v-bind:href="publicUrl"
           target="_blank">Ссылка на объект в Magic</a>
      </p>
      <p v-if="!hasToken && !hasRoleUser">
        <router-link v-bind:to="{ name: 'CalculationLog' }">Перейти на детальный расчет</router-link>
      </p>
      <p v-if="isCalcClaim">
        Тип объекта: заявка
      </p>
    </div>
    <div class="col-md-auto">
      <p class="mb-1"><strong>Рын. цена (оценка Эксперта): </strong>{{ getPrice('ratingByExpert') }}</p>
      <p class="mb-1"
         v-if="getPrice('priceMarket')"><strong>Рыночная цена, 3 мес. (Алгоритм): </strong>{{ getPrice('priceMarket') }}</p>
      <p class="mb-1"><strong>Цена выкупа (цена Эксперта): </strong>{{ getPrice('priceByValuer') }}</p>
      <p class="mb-1"
         v-if="externalUrl">
        <strong>Ссылка на объявление: </strong>
        <a v-bind:href="externalUrl"
           target="_blank">перейти</a>
      </p>
      <p class="mb-1"
         v-if="calculationCheckUrl">
        <strong>Ссылка на конкурентов: </strong>
        <a v-bind:href="calculationCheckUrl"
           target="_blank">перейти</a>
      </p>
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/lib/formatNumber.js';
import moment from 'moment';
import constants from '@/assets/data/grid_constants';

export default { 
  props: {
    sourceFields: [Array, Object]
  },
  computed: {
    publicUrl() {
      return this.sourceFields.publicUrl;
    },

    hasToken() {
      return this.$route.query.token;
    },

    externalUrl() {
      return this.sourceFields.externalUrl;
    },

    calculationCheckUrl() {
      return this.sourceFields.calculationCheckUrl;
    },

    hasRoleUser() {
      return this.$store.getters.getCurrentRole.includes('user');
    },

    isCalcClaim() {
      return this.sourceFields.__typename == 'InternalAdmin__InvestmentObject__CalcClaim' || this.sourceFields.__typename == 'InternalAdmin__InvestmentObject__CalcClaimForRoleUser';
    }
  },
  methods: {
    getDate(field) {
      const sourceVal = this.sourceFields[field];
      if (!sourceVal) return;
      const utcTime = moment.utc(sourceVal);
      return moment(utcTime).local().format(constants.momentFormat)
    },

    getPrice(field) {
      if (!this.sourceFields[field]) return;
      return `${formatNumber(this.sourceFields[field])} ₽`;
    }
  }
};
</script>
