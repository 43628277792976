<template>
  <div>
    <div class="img-inner">
      <img v-bind:src="el.imageUrl"
           v-if="el.imageUrl && calcStatus != 'not_images_for_score'">
      <div class="no-img">
        <svg-icon name="camera"></svg-icon>
        <p class="text-mutes">Не удалось загрузить изображение</p>
      </div>
    </div>
    <ul class="score-list"
        v-if="el.scores">
      <li v-for="(value, name) in el.scores"
          v-bind:key="`score_${name}`">
        <span v-bind:class="getScoreHighlightedClass(el.scores, name)">
          {{ getRepairTypeText(name) }}: {{ changeToPercent(value) }}
        </span>
      </li>
    </ul>
    <div v-else>Оценки нет</div>
    <p class="pt-2 mb-1 border-top mt-2">Укажите уровень ремонта:</p>
    <radio-group title="Уровень ремонта:"
                 v-bind:options="repairTypes"
                 v-bind:name="`repair_type_${index}`"
                 btnClass="secondary"
                 v-model="repair_type"
                 code="repair_type"
                 v-on:change="updateRadio"></radio-group>
    <p class="pt-2 mb-1">Укажите тип комнаты:</p>
    <radio-group title="Тип комнаты:"
                 v-bind:options="roomTypes"
                 v-bind:name="`room_type_${index}`"
                 btnClass="secondary"
                 v-model="room_type"
                 code="room_type"
                 v-on:change="updateRadio"></radio-group>
    <p class="text-danger"
       v-if="hasError">Не удалось отправить оценку</p>
  </div>
</template>

<script>
import RadioGroup from '@/components/form/RadioGroup.vue';
import RepairRatingBase from '@/components/object-form/RepairRatingBase.vue';
import errorHandler from '@/lib/ErrorHandler';

const GET_MARKUP_RECORD = 'https://parser.shiva-property.dev.realiste.ai/__developer/ml/repair_type_recognition/get_markup_records_public.json?key=dsadfcnxvkv';
const ADD_MARKUP_RECORD = 'https://parser.shiva-property.dev.realiste.ai/__developer/ml/repair_type_recognition/add_markup_record_public.json?key=dsadfcnxvkv';

export default {
  extends: RepairRatingBase,
  props: {
    el: Object,
    calcStatus: String,
    index: Number,
    modelVersion: String
  },
  data() {
    return {
      repair_type: '',
      room_type: '',
      hasError: false
    }
  },
  components: {
    RadioGroup
  },
  computed: {
    repairTypeMl() {
      if (this.el.scores == null) return 'not_an_interior';

      const scores = Object.values(this.el.scores);
      const maxVal = Math.max(...scores);

      return this.getKeyByValue(this.el.scores, maxVal);
    }
  },
  methods: {
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] == value);
    },
    rateRepair() {
      const params = {
        image_url: this.el.sourceImageUrl || '',
        image_proxy_url: this.el.imageUrl || '',
        model_version: this.modelVersion || '',
        repair_type_human: this.repair_type,
        room_type_human: this.room_type || '',
        repair_type_ml: this.repairTypeMl || '',
        referer: window.location.href
      }

      this.axios
        .get(ADD_MARKUP_RECORD, {
          params
        })
        .then(res => {
          this.hasError = false;
        })
        .catch((error) => {
          this.hasError = true;
          errorHandler.handleError(error, { message: 'Failed to send manual rating' })
          return error;
        });
    },
    updateRadio(obj) {
      this[obj.code] = obj.val;

      this.rateRepair()
    },
  },
  async mounted() {
    await this.axios
      .get(GET_MARKUP_RECORD, {
        params: {
          image_url: this.el.sourceImageUrl || ''
        }
      })
      .then(res => {
        const status = res.data.success;
        if (!status) {
          alert('Не удалось загрузить изменения');
        }
        this.repair_type = res.data.image.repair_type_human;
        this.room_type = res.data.image.room_type_human;
      })
      .catch((error) => {
        return error;
      });
  }
};
</script>

<style lang="scss" scoped>
.img-inner {
  height: 25rem;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  background: rgba(0,0,0,0.05);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .no-img {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    
    .svg-icon {
      opacity: .2;
    }
  }
}

.score-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.border-bottom {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.score-highlighted {
  padding: 0.2em;
  background-color: #fcf8e3;
}
</style>
